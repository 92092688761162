import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
const MyBusiness = () => {
  return (
    <div>
      <div class="d-flex flex-column vh-100">
        <header class="position-sticky top-0 bg-primary text-white">
          <div class="py-3">
            <div class="container">
              <div class="d-flex align-items-center">
                <div>
                  <a href="./" class="navbar">
                    <i class="fas fa-arrow-left"></i>
                  </a>
                </div>
                <h1 class="ms-3 mb-0">My Businesses</h1>
              </div>
            </div>
          </div>
        </header>

        <section class="py-3 flex-grow-1 bg-white">
          <div class="container h-100">
            <div class="py-3 border-bottom">
              <div class="row">
                <div class="col-4">
                  <div>
                    <img
                      src="https://adhakaro.com/assets/images/noimage.png"
                      alt=""
                      class="border border-2 rounded img-fluid"
                    />
                  </div>
                </div>
                <div class="col-8">
                  <h3 class="mb-0">Rudraksha Tech</h3>
                  <div class="text-primary mb-2">
                    {" "}
                    Restaurent, Education, Category
                  </div>
                  <p>
                    <i class="fas fa-location-dot">
                      <MdLocationOn />
                    </i>
                    1, Bhagwati colony, Near Apex Bharti School, Ratanada
                  </p>
                </div>
              </div>
              <div class="row g-1 mt-2">
                <div class="col d-grid">
                  <a
                    href="tel:<?php echo $b->phone ?>"
                    class="btn btn-sm btn-outline-primary"
                  >
                    <span class="fas fa-phone"></span> Call
                  </a>
                </div>
                <div class="col d-grid">
                  <a
                    href="mailto:<?php echo $b->email ?>"
                    class="btn btn-sm btn-outline-primary"
                  >
                    <span class="far fa-envelope"></span> Email
                  </a>
                </div>
                <div class="col d-grid">
                  <Link
                    to={"/business/rudraksha-tech"}
                    class="btn btn-sm btn-outline-primary"
                  >
                    <span class="far fa-plus"></span> View Details
                  </Link>
                </div>
              </div>
            </div>

            <div class="d-none" id="nextBtn">
              <button type="button" class="btn btn-primary">
                Next
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default MyBusiness;
