import { AiFillStar, AiOutlinePlus } from "react-icons/ai";

const Showcase = () => {
  return (
    <div class="py-2">
      <a href="product/add/<?php echo $business->slug ?>" class="float-end">
        <i class="far fa-plus"></i> Add More
      </a>
      <h4>Product Showcase</h4>
      <div class="row g-2">
        {/* <?php
            foreach ($products as $i => $p) :
            ?> */}
        <div class="col-4 mb-3">
          <div class="card product">
            <div class="card-body">
              <img
                src={require("../../../../images/6443b884a321d (1).webp")}
                alt="image"
                class="card-img-top"
              />

              <h6 class="card-title">as</h6>

              <div class="">
                {/* <!-- <div class="category">
                                                        <i class="fas fa-shopping-bag"></i> Shopping
                                                    </div> --> */}
                <div class="rating">
                  <span class="fas fa-star">
                    <AiFillStar />
                  </span>
                  <span class="fas fa-star">
                    <AiFillStar />
                  </span>
                  <span class="fas fa-star">
                    <AiFillStar />
                  </span>
                  <span class="fas fa-star">
                    <AiFillStar />
                  </span>
                  <span class="fas fa-star">
                    <AiFillStar />
                  </span>
                </div>
              </div>
              <div class="text-end">
                {/* <?php
                                if (!empty($p->price) && $p->price > $p->trade_price) :
                                ?> */}
                <del class="delete">₹1640</del>
                <big class="ms-1 price">₹999</big>
                {/* <?php
                                endif;
                                ?> */}
              </div>
              <div class="d-grid mt-2">
                <button class="btn btn-outline-primary btn-sm rounded-5">
                  <i class="far fa-plus">
                    <AiOutlinePlus />
                  </i>{" "}
                  ADD
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <?php
            endforeach;
            ?> */}
      </div>
    </div>
  );
};

export default Showcase;
