import { ImArrowLeft2 } from "react-icons/im";
import { Link } from "react-router-dom";

const SubCategory = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <header className="position-sticky top-0 bg-primary text-white">
        <div className="py-3">
          <div className="container">
            <div className="d-flex align-items-center">
              <div>
                <a
                  href="business/category/?cat_ids=<?php echo $_GET['cat_ids'] ?>"
                  className="navbar"
                >
                  <i className="fas fa-arrow-left">
                    <ImArrowLeft2 />
                  </i>
                </a>
              </div>
              <h1 className="ms-3 mb-0">Select Subcategory (Add Business)</h1>
            </div>
          </div>
        </div>
      </header>

      <section className="py-3 flex-grow-1 bg-white">
        <div className="container h-100">
          <h4>Education</h4>
          <div className="row">
            <div className="col-4">
              <label className="d-block select-container">
                <input
                  type="checkbox"
                  name="categories[]"
                  value="<?php echo $c->id ?>"
                  className="d-none category"
                ></input>
                <div className="select-block text-center">
                  <div>
                    <img src="<?php echo $c->icon ?>" alt="" />
                  </div>
                  <div>Category</div>
                </div>
              </label>
            </div>
          </div>

          <div className="form-Controll" id="nextBtn">
            <Link to={"/addbusiness"}>
              <button type="button" className="btn btn-primary">
                Next
              </button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubCategory;
