import { AiOutlinePlus } from "react-icons/ai";

const Gallery = () => {
  return (
    <div className="py-2">
      <a href="gallery/add/<?php echo $business->slug ?>" className="float-end">
        <i className="fas fa-plus">
          <AiOutlinePlus />
        </i>{" "}
        Add More
      </a>
      <h4 className="mb-3">Gallery</h4>
      <div className="row g-1">
        <div className="col-12 mb-3">
          <div className="product d-flex">
            <div className="mb-1">
              <img
                src={require("../../../../images/6443b884a321d (1).webp")}
                alt=""
                className="img img-fluid border rounded"
              />
              <h6 className="text-center mt-2">Do you know?</h6>
            </div>
            <div className="mb-1">
              <img
                src={require("../../../../images/64467ae2ccb07.webp")}
                alt=""
                className="img img-fluid border rounded"
              />
              <h6 className="text-center mt-2">HTML 5, Do you know?</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
