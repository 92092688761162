import { AiOutlineMail } from "react-icons/ai";
import { BsFillPencilFill, BsTelephoneFill } from "react-icons/bs";
import { FaGlobe } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { Link } from "react-router-dom";

const ProfileInfo = () => {
  return (
    <div class="py-2">
      <a href="profile/edit/<?php echo $business->slug ?>" class="float-end">
        <i class="fas fa-pencil">
          <BsFillPencilFill />
        </i>{" "}
        Edit
      </a>
      <h4 class="mb-3">Info</h4>
      <div class="border-bottom border-top py-2">
        <i class="fas fa-phone">
          <BsTelephoneFill />
        </i>
        &nbsp; +919636150842
      </div>
      <div class="border-bottom border-top py-2">
        <i class="far fa-envelope">
          <AiOutlineMail />
        </i>
        &nbsp; sudarshandubaga@gmail.com
      </div>
      <div class="border-bottom py-2 d-flex">
        <i class="fas fa-globe ">
          <FaGlobe />
          &nbsp;&nbsp;
        </i>
        <Link to={" https://rudrakshatech.com"} className="nav-link">
          https://rudrakshatech.com
        </Link>
      </div>
      {/* <?php
    endif;
    ?> */}
      <div class="border-bottom py-2">
        <i class="fas fa-location-dot">
          <MdLocationPin />
        </i>
        &nbsp; 1, Bhagwati Colony, Near Apex Bharti School, Ratanada
      </div>

      <div class="mt-3">
        <div class="rounded overflow-hidden border border-width-2">
          <iframe
            src="https://maps.google.com/maps?q=&hl=es&z=14&amp;output=embed"
            frameborder="0"
            style={{ width: "100%", height: 200, marginBottom: -7 }}
          ></iframe>
        </div>
      </div>

      <h4 class="my-3">Cities</h4>
      <div class="row">
        <div class="col-8 col-lg-9">
          {/* <select
            class="form-control basicAutoComplete"
            placeholder="Search City"
            autocomplete="off"
          ></select> */}
          <input
            type="text"
            placeholder="Search City"
            autocomplete="off"
            className="form-control basicAutoComplete"
          />
        </div>
        <div class="col-4 col-lg-3 d-grid">
          <button class="btn btn-primary">Add City</button>
        </div>
      </div>
    </div>
  );
};
export default ProfileInfo;
