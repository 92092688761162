import { ImArrowLeft2 } from "react-icons/im";

const AddBusiness = () => {
  return (
    <div class="d-flex flex-column vh-100">
      <header class="position-sticky top-0 bg-primary text-white">
        <div class="py-3">
          <div class="container">
            <div class="d-flex align-items-center">
              <div>
                <a
                  href="business/subcategory/?cat_ids=<?php echo $_GET['cat_ids'] ?>"
                  class="navbar"
                >
                  <i class="fas fa-arrow-left">
                    <ImArrowLeft2 />
                  </i>
                </a>
              </div>
              <h1 class="ms-3 mb-0">Business Info (Add New Business)</h1>
            </div>
          </div>
        </div>
      </header>

      <section class="py-3 flex-grow-1 bg-white d-flex flex-column">
        <div class="container">
          <form action="action/business" method="POST">
            <input
              type="hidden"
              name="category_ids"
              value="<?php echo $_GET['cat_ids'] ?>"
            />
            <div class="mb-3">
              <input
                type="text"
                name="form[name]"
                placeholder="Enter Business Name"
                class="form-control"
                required
              />
            </div>
            <div class="mb-3">
              <input
                type="email"
                name="form[email]"
                placeholder="Enter Business Email ID"
                class="form-control"
                required
              />
            </div>
            <div class="mb-3">
              <input
                type="tel"
                name="form[phone]"
                placeholder="Enter Mobile No."
                class="form-control"
                required
              />
            </div>
            <div class="mb-3">
              <input
                type="tel"
                name="form[phone2]"
                placeholder="Enter Phone (optional)"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <input
                type="tel"
                name="form[website]"
                placeholder="Enter Website (optional)"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <input
                type="tel"
                name="form[website]"
                placeholder="Full Address"
                class="form-control"
              />
            </div>
            <div class="mb-3">map</div>
            <div class="d-grid">
              <button class="btn btn-primary">
                SUBMIT <i class="fas fa-angle-right ms-2"></i>
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};
export default AddBusiness;
