import { Button } from "react-bootstrap";
import { ImArrowLeft2 } from "react-icons/im";
import { Link } from "react-router-dom";
const Category = () => {
  return (
    <>
      <div class="d-flex flex-column vh-100">
        <header class="position-sticky top-0 bg-primary text-white">
          <div class="py-3">
            <div class="container">
              <div class="d-flex align-items-center">
                <div>
                  <a href="./" class="navbar">
                    <i class="fas fa-arrow-left">
                      <ImArrowLeft2 />
                    </i>
                  </a>
                </div>
                <h1 class="ms-3 mb-0">Select Category (Add Business)</h1>
              </div>
            </div>
          </div>
        </header>

        <section class="py-3 flex-grow-1 bg-white">
          <div class="container h-100">
            <div class="row">
              <div class="col-4">
                <label class="d-block select-container">
                  <input
                    type="checkbox"
                    name="categories[]"
                    value="<?php echo $c->id ?>"
                    class="d-none category"
                  ></input>
                  <div class="select-block text-center">
                    <div>
                      <img
                        src={require("../../images/download.png")}
                        alt="image"
                      />
                    </div>
                    <div>Restaurent</div>
                  </div>
                </label>
              </div>
              <div class="col-4">
                <label class="d-block select-container">
                  <input
                    type="checkbox"
                    name="categories[]"
                    value="<?php echo $c->id ?>"
                    class="d-none category"
                  ></input>
                  <div class="select-block text-center">
                    <div>
                      <img
                        src={require("../../images/download (1).png")}
                        alt="image"
                      />
                    </div>
                    <div>Hotels</div>
                  </div>
                </label>
              </div>
              <div class="col-4">
                <label class="d-block select-container">
                  <input
                    type="checkbox"
                    name="categories[]"
                    value="<?php echo $c->id ?>"
                    class="d-none category"
                  ></input>
                  <div class="select-block text-center">
                    <div>
                      <img
                        src={require("../../images/download (2).png")}
                        alt="image"
                      />
                    </div>
                    <div>Shopping</div>
                  </div>
                </label>
              </div>
              <div class="col-4">
                <label class="d-block select-container">
                  <input
                    type="checkbox"
                    name="categories[]"
                    value="<?php echo $c->id ?>"
                    class="d-none category"
                  ></input>
                  <div class="select-block text-center">
                    <div>
                      <img
                        src={require("../../images/download (3).png")}
                        alt="image"
                      />
                    </div>
                    <div>Education</div>
                  </div>
                </label>
              </div>
              <div class="col-4">
                <label class="d-block select-container">
                  <input
                    type="checkbox"
                    name="categories[]"
                    value="<?php echo $c->id ?>"
                    class="d-none category"
                  ></input>
                  <div class="select-block text-center">
                    <div>
                      <img
                        src={require("../../images/download (4).png")}
                        alt="image"
                      />
                    </div>
                    <div>Events</div>
                  </div>
                </label>
              </div>
              <div class="col-4">
                <label class="d-block select-container">
                  <input
                    type="checkbox"
                    name="categories[]"
                    value="<?php echo $c->id ?>"
                    class="d-none category"
                  ></input>
                  <div class="select-block text-center">
                    <div>
                      <img
                        src={require("../../images/download (5).png")}
                        alt="image"
                      />
                    </div>
                    <div>Travels</div>
                  </div>
                </label>
              </div>
              <div class="col-4">
                <label class="d-block select-container">
                  <input
                    type="checkbox"
                    name="categories[]"
                    value="<?php echo $c->id ?>"
                    class="d-none category"
                  ></input>
                  <div class="select-block text-center">
                    <div>
                      <img
                        src={require("../../images/download (6).png")}
                        alt="image"
                      />
                    </div>
                    <div>IT Solution</div>
                  </div>
                </label>
              </div>
              <div class="col-4">
                <label class="d-block select-container">
                  <input
                    type="checkbox"
                    name="categories[]"
                    value="<?php echo $c->id ?>"
                    class="d-none category"
                  ></input>
                  <div class="select-block text-center">
                    <div>
                      <img
                        src={require("../../images/download (7).png")}
                        alt="image"
                      />
                    </div>
                    <div>Services</div>
                  </div>
                </label>
              </div>
            </div>

            <div className="d-grid" id="nextBtn">
              <Link to={"/subcategory"} className="btn btn-primary text-white">
                Next <i class="fas fa-angle-right ms-2"></i>
              </Link>
            </div>
            <div class="d-grid"></div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Category;
