const Deal = () => {
  return (
    <div className="py-2">
      <form action="" method="post" className="row mb-3">
        <div class="col-8">
          <input
            type="text"
            name="record[title]"
            className="form-control"
            placeholder="Enter New Service"
          />
        </div>
        <div className="col-4 d-grid">
          <button className="btn btn-primary">Save</button>
        </div>
      </form>

      <h4>Deals In</h4>
      {/* <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
        <?php
            foreach ($services as $s) :
            ?>
        <li className="border-bottom py-2"></li>
        <?php
            endforeach;
            ?>
      </ul> */}
      <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
        <li class="border-bottom py-2">Service 1</li>
        <li class="border-bottom py-2">Service 2</li>
      </ul>
    </div>
  );
};
export default Deal;
