import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import AddBusiness from "./Pages/AddBusiness";
import Category from "./Pages/Category";
import Home from "./Pages/Home";
import MyBusiness from "./Pages/MyBusiness";
import ViewDatails from "./Pages/MyBusiness/ViewDatails";
import Gallery from "./Pages/MyBusiness/ViewDatails/Gallery";
import ProfileInfo from "./Pages/MyBusiness/ViewDatails/ProfileInfo";
import Deal from "./Pages/MyBusiness/ViewDatails/Services/Deal";
import Showcase from "./Pages/MyBusiness/ViewDatails/Showcase";
import SubCategory from "./Pages/SubCategory";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="category" element={<Category />}></Route>
        <Route path="addbusiness" element={<AddBusiness />}></Route>
        <Route path="subcategory" element={<SubCategory />}></Route>
        <Route path="mybusiness" element={<MyBusiness />}></Route>
        <Route path="business/:slug" element={<ViewDatails />}>
          <Route index element={<Showcase />} />
          <Route path="service" element={<Deal />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="profileinfo" element={<ProfileInfo />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
