import { BsTagFill, BsTelephoneFill, BsWhatsapp } from "react-icons/bs";
import { FaBriefcase, FaImages } from "react-icons/fa";
import { FaArrowLeft, FaShare } from "react-icons/fa";
import { Link, NavLink, Outlet } from "react-router-dom";

const ViewDatails = () => {
  return (
    <>
      <div className="d-flex flex-column vh-100">
        <header className="position-sticky top-0 bg-primary text-white">
          <div className="py-3">
            <div className="container">
              <div className="d-flex align-items-center">
                <div>
                  <a href="./business/my-listing" className="navbar">
                    <i className="fas fa-arrow-left">
                      <FaArrowLeft />
                    </i>
                  </a>
                </div>
                <h1 className="ms-3 mb-0"> Rudraksha Tech</h1>
              </div>
            </div>
          </div>
        </header>

        <section className="py-3 flex-grow-1 bg-white">
          <div className="container h-100">
            <section className="position-relative">
              <div className="banner-image">
                <img
                  src="https://img.freepik.com/free-vector/stylish-glowing-digital-red-lines-banner_1017-23964.jpg"
                  alt=""
                  className="w-100 rounded"
                />
                <label>
                  <i className="fas fa-camera"></i> Change Banner
                  <input type="file" name="" className="d-none" />
                </label>
              </div>
              <div
                style={{
                  padding: " 0 10px",
                  marginTop: "-60px",
                  position: "relative",
                  Zindex: 1,
                }}
              >
                <div className="row">
                  <div className="col-4">
                    <div className="profile-image">
                      <img
                        src="https://adhakaro.com/assets/images/noimage.png"
                        alt="Profile Image"
                        className="border border-2 rounded img-fluid"
                        style={{ width: "100%" }}
                      />
                      <label for="profilePic">
                        <i className="fas fa-camera"></i> CHANGE
                      </label>
                    </div>
                    <input
                      type="file"
                      name="profile_pic"
                      id="profilePic"
                      className="d-none"
                    />
                  </div>
                  <div className="col-8">
                    <div className="py-2">
                      <h1 className="mb-0 text-white text-uppercase">
                        Rudraksha Tech
                      </h1>
                      <div className="text-light">
                        Restaurent, Education, Category
                      </div>

                      <div
                        className="row g-1 mt-2 text-center"
                        style={{ FontSize: "12px" }}
                      >
                        <div className="col d-grid text-center">
                          <a
                            href="tel:<?php echo $business->phone ?>"
                            className="btn btn-sm btn-outline-primary"
                          >
                            <span className="fas fa-phone">
                              <BsTelephoneFill />
                            </span>
                          </a>
                          Call
                        </div>
                        <div className="col d-grid">
                          <a
                            href="mailto:info@example.com"
                            className="btn btn-sm btn-outline-success text-success"
                          >
                            <span className="fab fa-whatsapp">
                              <BsWhatsapp />
                            </span>
                          </a>
                          Whatsapp
                        </div>
                        <div className="col d-grid">
                          <a
                            href="mailto:info@example.com"
                            className="btn btn-sm btn-outline-info text-info"
                          >
                            <span className="fas fa-share">
                              <FaShare />
                            </span>
                          </a>
                          Share
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <ul
              className="nav nav-pills nav-fill mt-1 profile-tab"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <NavLink
                  to={"/business/rudraksha-tech"}
                  className="nav-link"
                  activeClass="active"
                  end
                >
                  <i className="fas fa-briefcase">
                    <FaBriefcase />
                  </i>
                  &nbsp; Showcase
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  to={"/business/rudraksha-tech/service"}
                  className="nav-link "
                  activeClass="active"
                >
                  <i className="fas fa-tag">
                    <BsTagFill />
                  </i>
                  &nbsp; Deals-In
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  to={"/business/rudraksha-tech/gallery"}
                  className="nav-link "
                  activeClass="active"
                >
                  <i className="far fa-images">
                    <FaImages />
                  </i>
                  &nbsp; Gallery
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  to={"/business/rudraksha-texh/profileinfo"}
                  className="nav-link "
                  activeClass="active"
                >
                  <i className="far fa-user"></i> INFO
                </NavLink>
              </li>
            </ul>

            <Outlet />
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              ></div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabindex="0"
              ></div>
              <div
                className="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
                tabindex="0"
              ></div>
              <div
                className="tab-pane fade"
                id="pills-info"
                role="tabpanel"
                aria-labelledby="pills-disabled-tab"
                tabindex="0"
              ></div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default ViewDatails;
