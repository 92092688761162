// import { Container, Row, Col } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { GoInfo } from "react-icons/go";
import { GiByzantinTemple } from "react-icons/gi";
import { FaPowerOff, FaShoppingBag } from "react-icons/fa";
import {
  BsEnvelope,
  BsExclamationTriangleFill,
  BsFillBellFill,
  BsPersonLock,
  BsShop,
  BsTelephoneFill,
  BsWhatsapp,
} from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { MdLocationOn } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { FaEllipsisV } from "react-icons/fa";
import { BsChevronRight } from "react-icons/bs";
import {
  AiFillHome,
  AiFillStar,
  AiOutlineCheck,
  AiOutlineHeart,
  AiOutlinePlus,
} from "react-icons/ai";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";

const Home = () => {
  const homeSetting = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
  };
  // const []
  const homeSlider = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 2,
    speed: 500,
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        className="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasLeft"
        aria-labelledby="offcanvasLeftLabel"
      >
        <Button variant="primary" onClick={handleShow}>
          Launch
        </Button>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="offcanvas-header position-relative flex-column text-center justify-content-center align-items-center">
              <div className="text-center">
                <img
                  src={require("../../images/UNJ05AV8_400x400.jpeg")}
                  className="rounded-circle"
                  alt="Styled Image"
                  style={{ MaxWidth: 92 }}
                />
              </div>
              <div className="mt-3">
                <h5 className="offcanvas-title" id="offcanvasLeftLabel">
                  Rishu Jain
                </h5>
                <div>
                  <small>(9245xxxxxx)</small>
                </div>
              </div>
              {/* <button
                type="button"
                className="btn-close text-reset position-absolute"
                style={{ top: 10, right: 10 }}
                data-bs-dismiss="offcanvas"
                aria-label="closeButton"
              ></button> */}
            </div>

            <div className="offcanvas-body bg-primary text-white">
              <ul className="list-unstyled">
                <li>
                  <Link to={"myBusiness"}>
                    <i className="fas fa-shop">
                      <BsShop />
                    </i>
                    My Businesses
                  </Link>
                </li>
                <li>
                  <a href="https://theportfolioproject.com/jain-bazaar/#">
                    <i className="fas fa-om">
                      <GiByzantinTemple />
                    </i>
                    Temple
                  </a>
                </li>
                <li>
                  <a href="https://theportfolioproject.com/jain-bazaar/#">
                    <i className="fas fa-circle-info">
                      <GoInfo />
                    </i>{" "}
                    About Us
                  </a>
                </li>
                <li>
                  <a href="https://theportfolioproject.com/jain-bazaar/#">
                    <i className="fas fa-check">
                      <AiOutlineCheck />
                    </i>{" "}
                    Terms &amp; Conditions
                  </a>
                </li>
                <li>
                  <a href="https://theportfolioproject.com/jain-bazaar/#">
                    <i className="fas fa-user-lock">
                      <BsPersonLock />
                    </i>{" "}
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://theportfolioproject.com/jain-bazaar/#">
                    <i className="fas fa-triangle-exclamation">
                      <BsExclamationTriangleFill />
                    </i>{" "}
                    Disclaimer
                  </a>
                </li>
                <li>
                  <a href="https://theportfolioproject.com/jain-bazaar/#">
                    <i className="fas fa-power-off">
                      <FaPowerOff />
                    </i>{" "}
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <header class="position-sticky top-0 pb-2 back">
        <div class="py-3">
          <div class="container">
            <div class="d-flex align-items-center">
              <div>
                <button
                  class="navbar-icon"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasLeft"
                  aria-controls="offcanvasLeft"
                  onClick={handleShow}
                >
                  <i class="fas fa-bars"></i>
                  <FiMenu />
                </button>
              </div>
              <div class="location-icon ms-3">
                <i class="fas fa-location-dot text-primary textstyle">
                  <MdLocationOn />
                </i>
                All City
              </div>
              <div class="ms-1">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Jodhpur(Rajasthan)</option>
                  <option name="" id="">
                    Jaipur(Rajasthan)
                  </option>
                  <option name="" id="">
                    Udaipur(Rajasthan)
                  </option>
                </select>
              </div>
              <div class="ms-auto">
                <button class="notification bg-white">
                  <span class="badge">13</span>
                  <i class="far fa-bell">
                    <BsFillBellFill />
                  </i>
                </button>
              </div>
              <div class="ms-2">
                <button class="notification cart-counter bg-white">
                  <span class="badge">06</span>
                  <i class="fas fa-shopping-bag">
                    {" "}
                    <FaShoppingBag />
                  </i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div class="container">
            <div class="input-group search-bar">
              <label class="input-group-text text-center pe-0" htmlFor="search">
                <i class="fas fa-search">
                  <FiSearch />
                </i>
              </label>
              <input
                type="search"
                id="search"
                class="form-control"
                placeholder="Search Here..."
              />
            </div>
          </div>
        </section>
      </header>

      <section class="mt-3">
        <div class="container mt-4">
          <div>
            <Slider {...homeSetting}>
              <div className="slider-item">
                <h3></h3>
              </div>
              <div className="slider-item">
                <h3></h3>
              </div>
              <div className="slider-item">
                <h3></h3>
              </div>
              <div className="slider-item">
                <h3></h3>
              </div>
              <div className="slider-item">
                <h3></h3>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section class="mb-3">
        <div class="container">
          <div class="rounded-4">
            <div class="row g-0">
              <div class="col-2">
                <div class="category-block">
                  <div class="icon">
                    <img
                      src={require("../../images/download.png")}
                      alt="image"
                    />
                  </div>
                  <div class="label text-center">Restaurent</div>
                </div>
              </div>
              <div class="col-2">
                <div class="category-block">
                  <div class="icon">
                    <img
                      src={require("../../images/download (1).png")}
                      alt="image"
                    />
                  </div>
                  <div class="label text-center">Hotels</div>
                </div>
              </div>
              <div class="col-2">
                <div class="category-block">
                  <div class="icon">
                    <img
                      src={require("../../images/download (2).png")}
                      alt="image"
                    />
                  </div>
                  <div class="label text-center">Shopping</div>
                </div>
              </div>
              <div class="col-2">
                <div class="category-block">
                  <div class="icon">
                    <img
                      src={require("../../images/download (3).png")}
                      alt="image"
                    />
                  </div>
                  <div class="label text-center">Education</div>
                </div>
              </div>
              <div class="col-2">
                <div class="category-block">
                  <div class="icon">
                    <img
                      src={require("../../images/download (4).png")}
                      alt="image"
                    />
                  </div>
                  <div class="label text-center">Events</div>
                </div>
              </div>
              <div class="col-2">
                <div class="category-block">
                  <div class="icon">
                    <img
                      src={require("../../images/download (5).png")}
                      alt="image"
                    />
                  </div>
                  <div class="label text-center">Travels</div>
                </div>
              </div>
              <div class="col-2">
                <div class="category-block">
                  <div class="icon">
                    <img
                      src={require("../../images/download (6).png")}
                      alt="image"
                    />
                  </div>
                  <div class="label text-center">IT Solutions</div>
                </div>
              </div>
              <div class="col-2">
                <div class="category-block">
                  <div class="icon">
                    <img
                      src={require("../../images/download (7).png")}
                      alt="image"
                    />
                  </div>
                  <div class="label text-center">Services</div>
                </div>
              </div>
              <div class="col-2">
                <div class="category-block">
                  <div class="icon">
                    <img
                      src={require("../../images/show_all.png")}
                      alt="image"
                      width={50} //quest-mall.jpeg
                    />
                  </div>
                  <div class="label text-center">Show All</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="my-3">
        <div class="container mt-4">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h3 class="mb-0">Recommended Businesses</h3>
            <a href="https://theportfolioproject.com/jain-bazaar/#">
              Show All{" "}
              <i class="fas fa-angle-right">
                <BsChevronRight />
              </i>
            </a>
          </div>
          <div>
            {/* <!-- Set up your HTML --> */}

            <Slider {...homeSlider}>
              <div>
                <h3>
                  <div
                    class="owl-item active"
                    style={{ width: 304.4, MarginRight: 10 }}
                  >
                    <div class="bg-white rounded-3 overflow-hidden">
                      <div class="business-block bg-white">
                        <div class="image">
                          <img
                            src={require("../../images/quest-mall.jpeg")}
                            alt="image"
                            width={50}
                          />
                        </div>
                        <div class="details px-1 py-2">
                          <h4 class="mb-0">
                            Business Title Business Title Business Title
                          </h4>
                          <div class="">
                            <div class="category">
                              <i class="fas fa-shopping-bag">
                                <FaShoppingBag />
                              </i>
                              &nbsp; Shopping
                            </div>
                            <div class="rating">
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="contact row g-0 border-top">
                        <div class="col-4 text-center py-2 border-end">
                          <i class="fas fa-phone">
                            <BsTelephoneFill />
                          </i>
                        </div>
                        <div class="col-4 text-center py-2 border-end">
                          <i class="far fa-envelope">
                            <BsEnvelope />
                          </i>
                        </div>
                        <div class="col-4 text-center py-2">
                          <i class="fa-brands fa-whatsapp">
                            <BsWhatsapp />
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </h3>
              </div>
              <div>
                <h3>
                  <div
                    class="owl-item active"
                    style={{ width: 304.4, MarginRight: 10 }}
                  >
                    <div class="bg-white rounded-3 overflow-hidden">
                      <div class="business-block bg-white">
                        <div class="image">
                          <img
                            src={require("../../images/quest-mall.jpeg")}
                            alt="image"
                            width={50}
                          />
                        </div>
                        <div class="details px-1 py-2">
                          <h4 class="mb-0">
                            Business Title Business Title Business Title
                          </h4>
                          <div class="">
                            <div class="category">
                              <i class="fas fa-shopping-bag">
                                <FaShoppingBag />
                              </i>{" "}
                              &nbsp; Shopping
                            </div>
                            <div class="rating">
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="contact row g-0 border-top">
                        <div class="col-4 text-center py-2 border-end">
                          <i class="fas fa-phone">
                            <BsTelephoneFill />
                          </i>
                        </div>
                        <div class="col-4 text-center py-2 border-end">
                          <i class="far fa-envelope">
                            <BsEnvelope />
                          </i>
                        </div>
                        <div class="col-4 text-center py-2">
                          <i class="fa-brands fa-whatsapp">
                            <BsWhatsapp />
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </h3>
              </div>
              <div>
                <h3>
                  <div
                    class="owl-item active"
                    style={{ width: 304.4, MarginRight: 10 }}
                  >
                    <div class="bg-white rounded-3 overflow-hidden">
                      <div class="business-block bg-white">
                        <div class="image">
                          <img
                            src={require("../../images/quest-mall.jpeg")}
                            alt="image"
                            width={50}
                          />
                        </div>
                        <div class="details px-1 py-2">
                          <h4 class="mb-0">
                            Business Title Business Title Business Title
                          </h4>
                          <div class="">
                            <div class="category">
                              <i class="fas fa-shopping-bag">
                                <FaShoppingBag />
                              </i>
                              &nbsp; Shopping
                            </div>
                            <div class="rating">
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="contact row g-0 border-top">
                        <div class="col-4 text-center py-2 border-end">
                          <i class="fas fa-phone">
                            <BsTelephoneFill />
                          </i>
                        </div>
                        <div class="col-4 text-center py-2 border-end">
                          <i class="far fa-envelope">
                            <BsEnvelope />
                          </i>
                        </div>
                        <div class="col-4 text-center py-2">
                          <i class="fa-brands fa-whatsapp">
                            <BsWhatsapp />
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </h3>
              </div>
              <div>
                <h3>
                  <div
                    class="owl-item active"
                    style={{ width: 304.4, MarginRight: 10 }}
                  >
                    <div class="bg-white rounded-3 overflow-hidden">
                      <div class="business-block bg-white">
                        <div class="image">
                          <img
                            src={require("../../images/quest-mall.jpeg")}
                            alt="image"
                            width={50}
                          />
                        </div>
                        <div class="details px-1 py-2">
                          <h4 class="mb-0">
                            Business Title Business Title Business Title
                          </h4>
                          <div class="">
                            <div class="category">
                              <i class="fas fa-shopping-bag">
                                <FaShoppingBag />
                              </i>
                              &nbsp; Shopping
                            </div>
                            <div class="rating">
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="contact row g-0 border-top">
                        <div class="col-4 text-center py-2 border-end">
                          <i class="fas fa-phone">
                            <BsTelephoneFill />
                          </i>
                        </div>
                        <div class="col-4 text-center py-2 border-end">
                          <i class="far fa-envelope">
                            <BsEnvelope />
                          </i>
                        </div>
                        <div class="col-4 text-center py-2">
                          <i class="fa-brands fa-whatsapp">
                            <BsWhatsapp />
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </h3>
              </div>
              <div>
                <h3>
                  <div
                    class="owl-item active"
                    style={{ width: 304.4, MarginRight: 10 }}
                  >
                    <div class="bg-white rounded-3 overflow-hidden">
                      <div class="business-block bg-white">
                        <div class="image">
                          <img
                            src={require("../../images/quest-mall.jpeg")}
                            alt="image"
                            width={50}
                          />
                        </div>
                        <div class="details px-1 py-2">
                          <h4 class="mb-0">
                            Business Title Business Title Business Title
                          </h4>
                          <div class="">
                            <div class="category">
                              <i class="fas fa-shopping-bag">
                                <FaShoppingBag />
                              </i>
                              &nbsp; Shopping
                            </div>
                            <div class="rating">
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="contact row g-0 border-top">
                        <div class="col-4 text-center py-2 border-end">
                          <i class="fas fa-phone">
                            <BsTelephoneFill />
                          </i>
                        </div>
                        <div class="col-4 text-center py-2 border-end">
                          <i class="far fa-envelope">
                            <BsEnvelope />
                          </i>
                        </div>
                        <div class="col-4 text-center py-2">
                          <i class="fa-brands fa-whatsapp">
                            <BsWhatsapp />
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </h3>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section class="my-3">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <h3 class="mb-0">Recommended Products</h3>
            <a href="https://theportfolioproject.com/jain-bazaar/#">
              Show All{" "}
              <i class="fas fa-angle-right">
                <BsChevronRight />
              </i>
            </a>
          </div>
          <div>
            {/* <!-- Set up your HTML --> */}

            <Slider {...homeSlider}>
              <h3>
                <div
                  class="owl-item active"
                  style={{ width: 304.4, MarginRight: 10 }}
                >
                  <div>
                    <div class="bg-white rounded-3 overflow-hidden">
                      <div class="business-block bg-white">
                        <button class="fav">
                          <i class="far fa-heart">
                            <AiOutlineHeart />
                          </i>
                        </button>
                        <div class="image">
                          <img
                            src={require("../../images/41vXn0bEpHL.jpeg")}
                            alt="images"
                          />
                          <div class="label-text">NEW</div>
                        </div>
                        <div class="details px-1 py-2">
                          <h4 class="mb-0">Product Title</h4>
                          <div class="">
                            <div class="category">
                              <i class="fas fa-shopping-bag">
                                <FaShoppingBag />
                              </i>{" "}
                              Shopping
                            </div>
                            <div class="rating">
                              <span class="fas fa-star">
                                {" "}
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                {" "}
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                {" "}
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                {" "}
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                {" "}
                                <AiFillStar />
                              </span>
                            </div>
                          </div>
                          <div class="text-end">
                            <del class="delete">₹1500</del>{" "}
                            <big class="ms-1 price">₹999</big>
                          </div>
                          <div class="d-grid mt-2">
                            <button class="btn btn-outline-primary btn-sm rounded-5">
                              <i class="far fa-plus">
                                <AiOutlinePlus />
                              </i>{" "}
                              ADD
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </h3>
              <h3>
                <div
                  class="owl-item active"
                  style={{ width: 304.4, MarginRight: 10 }}
                >
                  <div>
                    <div class="bg-white rounded-3 overflow-hidden">
                      <div class="business-block bg-white">
                        <button class="fav">
                          <i class="far fa-heart"></i>
                        </button>
                        <div class="image">
                          <img
                            src={require("../../images/P_setting_xxx_0_90_end_692.png")}
                            alt="images"
                          />
                          <div class="label-text">NEW</div>
                        </div>
                        <div class="details px-1 py-2">
                          <h4 class="mb-0">Product Title</h4>
                          <div class="">
                            <div class="category">
                              <i class="fas fa-shopping-bag">
                                <FaShoppingBag />
                              </i>{" "}
                              Shopping
                            </div>
                            <div class="rating">
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                            </div>
                          </div>
                          <div class="text-end">
                            <del class="delete">₹1500</del>{" "}
                            <big class="ms-1 price">₹999</big>
                          </div>
                          <div class="d-grid mt-2">
                            <button class="btn btn-outline-primary btn-sm rounded-5">
                              <i class="far fa-plus">
                                <AiOutlinePlus />
                              </i>{" "}
                              ADD
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </h3>
              <h3>
                <div
                  class="owl-item active"
                  style={{ width: 304.4, MarginRight: 10 }}
                >
                  <div>
                    <div class="bg-white rounded-3 overflow-hidden">
                      <div class="business-block bg-white">
                        <button class="fav">
                          <i class="far fa-heart"></i>
                        </button>
                        <div class="image">
                          <img
                            src={require("../../images/P_setting_xxx_0_90_end_692.png")}
                            alt="images"
                          />
                          <div class="label-text">NEW</div>
                        </div>
                        <div class="details px-1 py-2">
                          <h4 class="mb-0">Product Title</h4>
                          <div class="">
                            <div class="category">
                              <i class="fas fa-shopping-bag">
                                <FaShoppingBag />
                              </i>{" "}
                              Shopping
                            </div>
                            <div class="rating">
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                            </div>
                          </div>
                          <div class="text-end">
                            <del class="delete">₹1500</del>{" "}
                            <big class="ms-1 price">₹999</big>
                          </div>
                          <div class="d-grid mt-2">
                            <button class="btn btn-outline-primary btn-sm rounded-5">
                              <i class="far fa-plus">
                                <AiOutlinePlus />
                              </i>{" "}
                              ADD
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </h3>
              <h3>
                <div
                  class="owl-item active"
                  style={{ width: 304.4, MarginRight: 10 }}
                >
                  <div>
                    <div class="bg-white rounded-3 overflow-hidden">
                      <div class="business-block bg-white">
                        <button class="fav">
                          <i class="far fa-heart"></i>
                        </button>
                        <div class="image">
                          <img
                            src={require("../../images/41vXn0bEpHL.jpeg")}
                            alt="images"
                          />
                          <div class="label-text">NEW</div>
                        </div>
                        <div class="details px-1 py-2">
                          <h4 class="mb-0">Product Title</h4>
                          <div class="">
                            <div class="category">
                              <i class="fas fa-shopping-bag">
                                <FaShoppingBag />
                              </i>{" "}
                              Shopping
                            </div>
                            <div class="rating">
                              <span class="fas fa-star"></span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                            </div>
                          </div>
                          <div class="text-end">
                            <del class="delete">₹1500</del>{" "}
                            <big class="ms-1 price">₹999</big>
                          </div>
                          <div class="d-grid mt-2">
                            <button class="btn btn-outline-primary btn-sm rounded-5">
                              <i class="far fa-plus">
                                <AiOutlinePlus />
                              </i>{" "}
                              ADD
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </h3>
              <h3>
                <div
                  class="owl-item active"
                  style={{ width: 304.4, MarginRight: 10 }}
                >
                  <div>
                    <div class="bg-white rounded-3 overflow-hidden">
                      <div class="business-block bg-white">
                        <button class="fav">
                          <i class="far fa-heart"></i>
                        </button>
                        <div class="image">
                          <img
                            src={require("../../images/P_setting_xxx_0_90_end_692.png")}
                            alt="images"
                          />
                          <div class="label-text">NEW</div>
                        </div>
                        <div class="details px-1 py-2">
                          <h4 class="mb-0">Product Title</h4>
                          <div class="">
                            <div class="category">
                              <i class="fas fa-shopping-bag">
                                <FaShoppingBag />
                              </i>{" "}
                              Shopping
                            </div>
                            <div class="rating">
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                              <span class="fas fa-star">
                                <AiFillStar />
                              </span>
                            </div>
                          </div>
                          <div class="text-end">
                            <del class="delete">₹1500</del>{" "}
                            <big class="ms-1 price">₹999</big>
                          </div>
                          <div class="d-grid mt-2">
                            <button class="btn btn-outline-primary btn-sm rounded-5">
                              <i class="far fa-plus">
                                <AiOutlinePlus />
                              </i>{" "}
                              ADD
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </h3>
            </Slider>
          </div>
        </div>
      </section>

      <div class="" style={{ height: 50 }}></div>

      <footer class="py-1">
        <div class="container">
          <div class="bg-primary rounded">
            <div class="row align-items-center">
              <div class="col">
                <div>
                  <i class="fas fa-home">
                    <AiFillHome />
                  </i>
                </div>
                <div class="footer-text">HOME</div>
              </div>
              <div class="col">
                <div>
                  <i class="fas fa-shopping-bag">
                    <FaShoppingBag />
                  </i>
                </div>
                <div class="footer-text">BAZAAR</div>
              </div>
              <div class="col">
                <div class="f-menu">
                  <div class="add-menu">
                    <div class="inner-circle">
                      <i class="far fa-plus">
                        <AiOutlinePlus />
                      </i>
                    </div>
                    <Link to={"category"}></Link>
                  </div>
                </div>
              </div>
              <div class="col">
                <div>
                  <i class="fas fa-calendar-day">
                    <SlCalender />
                  </i>
                </div>
                <div class="footer-text">EVENTS</div>
              </div>
              <div class="col">
                <div>
                  <i class="fas fa-ellipsis-v">
                    <FaEllipsisV />
                  </i>
                </div>
                <div class="footer-text">MORE</div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Home;
